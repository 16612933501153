import React, {useEffect} from "react"
import {useDispatch, useSelector} from "react-redux"

import "./confirmation-record.scss"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Header from "../components/header/header"
import YourOrder from "../containers/singleInformation/summary/your-order"
import {resetRegistration} from "../redux/registration/registrationAction"

const ConfirmationPaymentPage = () => {
    const dispatch = useDispatch()
    const { ticket, options } = useSelector(({registrationReducer}) => registrationReducer)
    
      useEffect(() => {
        return () => {
            dispatch(resetRegistration())
        }
    }, [])

    const getVAT = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    const getTotal = () => {
        let total = ticket !== null ? parseFloat(ticket.packages_price) : 0

        options.forEach(option => {
            total = total + parseFloat(option.options_price)
        })

        return parseFloat(total).toFixed(2)
    }

    return (
        <Layout main={false}>
            <SEO title="Confirmation payment" />
            <Header/>
            <div className={"page"}>
                <h2 className={"page-title"}>Payment succeeded</h2>

            <p>
                Thank you for registering for the 51st Union World Conference on Lung Health.
            </p>
            <div className={"col-lg-8 mx-auto mt-1"}>
                <YourOrder ticket={ticket} options={options} vat={getVAT()} total={getTotal()}/>
            </div>    

            <p>
                You will be receiving your email confirmation soon. Please check also your Junk folder.<br/>Post-graduate courses and workshops will be available to book by July<br/>You will be able to add options to your registration until 12 October<br/><small>(fees applicable will correspond to the modification date)</small></p>

            <p>
                After this date, in your account, you will be able to download your confirmation and your receipt and other information.
            </p>                                                                   
            <p>
                We look forward to seeing you in Seville!
            </p>
            <p>
                <strong>The Union 2020 Team</strong>
            </p>
            </div>
            <div className={"content-footer footer-alone"}>
            <a href="https://union2020.urevent.fr/login" className={"sr-btn-next btn btn-info"} >Login</a>
        </div>
        </Layout>
    )
}

export default ConfirmationPaymentPage
